@import 'common/styles/colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.searchContainer {
  max-width: unset !important;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  margin: 0rem 0rem 1rem 0rem;
  flex-wrap: wrap;

  > div {
    margin-right: 24px !important;
  }

  > div:last-child {
    margin-right: 0 !important;
  }
}

.searchBar {
  flex: 2 1 0;
}

.filterButton {
  margin-top: 13px;
}
