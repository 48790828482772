@import 'common/styles/colors.module.scss';

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45vw;
  max-height: 45vh;
  border-radius: 5px;
  padding: 0;
  box-shadow: 24px;
  background-color: $white;

  @media only screen and (min-width: 768px) {
    width: 40vw;
    height: 40vh;
  }
}

.header {
  background: $white;
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  border-bottom: 2px solid $light-grey-bg-color;
}

.subTitle {
  color: $heavy-grey;
  font-weight: 600;
  font-size: 1.1em;
  max-height: fit-content;
}

.iconClose {
  float: right;
  cursor: pointer;
}

.content {
  background: $white;
  padding: 1rem;
  overflow-y: auto;
  max-height: 80vh;
}

.footer {
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  background: $white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 0px 0px 5px 5px;
  border-top: 2px solid $light-grey-bg-color;
}

.buttonCancel {
  margin-right: 1rem;
  color: $entelect-blue;
  border-color: $entelect-blue;
  font-weight: 600;
}

.buttonSubmit {
  color: $white;
  background-color: $entelect-blue;
  font-weight: 600;

  &:disabled {
    background-color: $disabled !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-grey;
  border-radius: 20px;
  border-right: 1px solid $white;
}
