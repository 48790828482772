@import 'common/styles/colors.module.scss';

.table {

  thead {
    background-color: $light-grey;
  }

  th,
  td {
    padding: 8px;
    border-right: none;
    border-left: none;
  }
}