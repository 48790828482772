@import 'common/styles/colors.module.scss';

.formHeading {
  font-size: 18px;
  font: Roboto;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 10px;
}

.formSubHeading {
  font-size: 12px;
  font-weight: 400;
}

.pageContainer {
  width: 50%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 25%;
}

.error {
  color: $error;
}

@media screen and (max-width: 1024px) {
  .pageContainer {
    width: 100%;
  }
}

.actions {
  margin-top: 15px;
}

.openDayWidth {
  width: 90%;
  margin-left: 2%;
}

.headingContainer {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.btnContainer {
  width: 80%;
}

.buttonWidth {
  width: 50%;
}

.btnContainerMobile {
  width: 100%;
}

@media (max-width< 1600px) {
  .headingContainer {
    margin-left: 32px;
  }
}

.submitButtonslayout {
  display: flex;
  justify-content: flex-end;
}

.openDayHeading {
  margin-top: 0px;
  margin-bottom: 0px;
}

.listItemText > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
