@import 'common/styles/colors.module.scss';

.appContainer {
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 2px 6px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    padding: 2px 8px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    padding: 2px 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    padding: 2px 16px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    padding: 2px 16px;
  }
}

.background {
  background-color: #eeeeee;
}

.headerRoot {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.loadingBarContainer {
  height: 0.3em;
  position: sticky;
  top: 0;
  z-index: 1;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    background-color: $entelect-blue;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    background-color: white;
  }
}

.loadingBar {
  position: absolute;
  width: 100%;
}
