.usersListHeaders {
  margin: 0px 16px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #4a5568;
}
