$color-gray-1: #333333;
$color-gray-2: #7a8598;
$color-gray-3: #c4c4c4;
$color-gray-4: #e1e1e1;
$color-gray-5: #eeeeee;
$color-gray-6: #f0f0f0;
$color-primary: #133970;
$color-primary-light: #476b97;
$color-secondary: #00a9b2;
$color-secondary-light: #e5eaef;
$color-tertiary: #2ecc40;
$color-red: #ff4136;
$color-white: #fff;
$color-white-1: #f6f6f6;
$color-black: #000000;
$color-green: #8abb4f;
$color-green2: #008d48;
$color-notification-blue: #bee3f8;
$color-orange: #ffc33e;

$entelect-blue: #133970;
$active-pre-calibration-accordion: #fff2e2;
$active-interview-accordion: #f2f6fe;
$active-post-calibration-accordion: #f5ffe7;
$entelect-green: #8abb4f;
$warning: #da823b;
$success: hsl(133, 34%, 50%);
$white: #ffffff;
$black: #000000;
$disabled: rgba(0, 0, 0, 0.2);
$white-smoke: whitesmoke;
$error: #f44336;
$checkbox-green: #6daa23;
$disabled-button: #7b7b7b;
$negative-button: #c23f38;
$color-notification-blue: #bee3f8;
$alternative-bg-color: #eeeeee;
$light-grey-bg-color: #f6f6f6;
$heavy-grey: #4a5568;
$medium-grey: #535353;
$light-grey: #d9d9d9;
$grey: #c4c4c4;
$session-text-color: #4a5568;
$chip-border-color: #009499;
$chip-bg-color: #dff7f7;
$chip-bg-grey: #e1e1e1;
$title-grey: #7b7b7b;
$paragraph-grey: #555555;
$scrollbar-grey: #bdbbbb;
$card-bg-color: #fafafa;
$card-border-color: #e7e7e7;
$back-arrow: #002850;
$color-team-green: #f3fde6;

:export {
  entelectBlue: $entelect-blue;
  activePreCalibrationAccordion: $active-pre-calibration-accordion;
  activeInterviewAccordion: $active-interview-accordion;
  activePostCalibrationAccordion: $active-post-calibration-accordion;
  entelectGreen: $entelect-green;
  warning: $warning;
  success: $success;
  white: $white;
  black: $black;
  disabled: $disabled;
  whiteSmoke: $white-smoke;
  error: $error;
  checkBoxGreen: $checkbox-green;
  disabledButton: $disabled-button;
  negativeButton: $negative-button;
  notificationBlue: $color-notification-blue;
  alternativeBgColor: $alternative-bg-color;
  lightGreyBgColor: $light-grey-bg-color;
  heavyGrey: $heavy-grey;
  mediumGrey: $medium-grey;
  lightGrey: $light-grey;
  grey: $grey;
  $colorTeamGreen: $color-team-green;
  sessionTextColor: $session-text-color;
  titleGrey: $title-grey;
  paragraphGey: $paragraph-grey;
  scrollbar-grey: $scrollbar-grey;
  chip-bg-grey: $chip-bg-grey;
  card-bg-color: $card-bg-color;
  card-border-color: $card-border-color;
  back-arrow: $back-arrow;
}
