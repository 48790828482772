@import 'common/styles/colors.module.scss';

.openDayHeaderWrapper {
  text-align: right;
  font-weight: 500;
  font-size: 25px;
  color: $title-grey;
}

.filtersWrapper {
  display: flex;
  margin-top: 15px;
}

.toggleWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}

.buttonGroup {
  & > button {
    padding: 0.4em;

    &:hover {
      cursor: pointer;
    }
  }
}

.backButton {
  display: flex;
}

.calibrationComponentWrapper {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.activePreCalibration {
  background-color: #f7931d;
  color: $white;
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
  border-width: thin;
}

.nonActivePreCalibration {
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
  border-width: thin;
}

.nonActivePreCalibration:hover {
  background-color: #f7931d;
  color: $white;
}

.activeInterview {
  background-color: #0089cf;
  color: $white;
  border-width: thin;
}

.nonActiveInterview {
  border-width: thin;
}

.nonActiveInterview:hover {
  background-color: #0089cf;
  color: $white;
}

.activePostCalibration {
  background-color: #6daa23;
  color: $white;
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  border-width: thin;
}

.nonActivePostCalibration {
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  border-width: thin;
}

.nonActivePostCalibration:hover {
  background-color: #6daa23;
  color: $white;
}

.PreCalibrationOrange {
  background-color: #f7931d;
}

.InterviewBlue {
  background-color: #0089cf;
}

.redds {
  background-color: #ff0000;
}

.PostCalibrationGreen {
  background-color: #6daa23;
}

.sortingArrow {
  margin-top: -1px;
}

.candidateHeader {
  display: flex;
  justify-content: left;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.offerWrapper {
  display: flex;
  justify-content: left;
  font-style: normal;
  line-height: 18px;
}

.offerHeader {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.offerCounts {
  font-size: 10px;
  margin-left: 10px;
}

.candidatesListWrapper {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.candidatesList {
  display: inline-grid;
  justify-content: space-between;
  grid-template-columns: 20% 10% 10% 20% 10% 11% 10%;
  width: 100%;
  color: $session-text-color;

  & > span {
    margin-left: 12px;
  }
}

.expandCollpaseAction {
  display: flex;
  justify-content: flex-end;
  color: $entelect-blue;
  cursor: pointer;
}

.summaryAction {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.backArrow {
  color: $back-arrow;
  min-height: 25px;
  min-width: 25px;
}

.filterContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.filterButtonContainer {
  color: white;
  background-color: $entelect-blue;
  min-width: 24px;
  padding: 6px;
  border-radius: 5px;
  display: block;
}

.matrixButton {
  height: 37px;
  gap: unset;
}
