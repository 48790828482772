.row {
  border: solid #dfdfdf 1px;
  margin: 7px 0 0 -7px !important;
  padding: 0;
  border-radius: 12px;

  & > div {
    padding-top: 0 !important;
    align-content: center;
  }

  p {
    span:nth-child(1) {
      margin-right: 1em;

      @media only screen and (min-width: 768px) {
        display: none !important;
      }
    }

    span:nth-child(2) {
      &.title {
        font-size: 1.5em;
      }

      @media only screen and (max-width: 768px) {
        font-weight: bold;
      }
    }
  }
}

.header {
  padding: 0.1em 1.2em;
  color: grey;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.actionButtons {
  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 2em;
    margin-top: -6px;
  }
}
