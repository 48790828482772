@import '../../../../common/styles/colors.module.scss';

.menuBar {
  background: #133970 !important;
  max-height: 64px;
}

.brandName {
  height: 35px;
  margin-right: 10px;
}

.brandImageLogo {
  margin-left: 0;
}

nav {
  display: flex;

  a {
    color: #fff;
    display: block;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.02857em;
    line-height: 1.75;
    padding: 1.1rem;
    text-decoration: none;
    cursor: pointer;

    &.active,
    &.active:hover {
      color: $color-green;
    }
  }
}

.authUser {
  align-items: center;
  margin-inline: 6px;
  width: auto !important;
}

.burgerMenuContainer {
  justify-content: flex-end;
}

.link {
  text-decoration: none;
  color: $color-black;
}

.mb0 {
  margin-bottom: 0;
}
