@import '../../../../common/styles/colors.module.scss';

.title {
  font-weight: 800;
  font-size: 30px;
}

.sidebar {
  height: 90vh;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.sidebarMobile {
  /* Small devices (phones, 768px and down) */
  @media only screen and (max-width: 768px) {
    margin: 0px 0px;
  }
}

.content {
  height: 90vh;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: hidden;
}

.boxShadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.sidebarHeader {
  border-radius: 5px 5px 0px 0px;
  background-color: #133970;
  height: 5rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  /* Small devices (phones, 768px and down) */
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
  }
}

.sidebarContent {
  overflow-y: auto;
}

.memberList {
  height: calc(90vh - 11em);
  overflow-y: auto;
}

.sidebarGoBackLink {
  padding: 1.5rem 0.5rem;
}

.sidebarHeaderContent {
  text-align: center;
  width: -webkit-fill-available;
  padding-right: 3rem;
}

.teamRow {
  font-size: 17px;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;

  &New {
    @extend .teamRow;
    color: #133970;
  }

  &Disabled {
    @extend .teamRow;
    cursor: not-allowed;
    color: $color-gray-2;
    display: none;
  }
}

.teamRowMember {
  font-size: 15px;
  margin-left: 1.5rem;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &New {
    @extend .teamRowMember;
    color: #133970;
  }

  & > span {
    display: inline-block;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Disabled {
    @extend .teamRow;
    cursor: not-allowed;
    color: $color-gray-2;
    display: none;
  }
}

.teamSelected {
  background-color: $color-gray-6;
}

.teamRowSelected {
  font-weight: 600;
}

.teamRowSelectedColor {
  background-color: $color-green;
  display: inline-block;
  width: 5px !important;
  height: 12px;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-left: -0.8rem;
}

.teamRowMemberSelectedColor {
  background-color: $color-green;
  display: inline-block;
  width: 5px !important;
  height: 5px;
  border-radius: 5px;
  margin-left: -0.8rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.searchContainer {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 1rem 0rem !important;
}

.deleteButtonContainer {
  float: right;
}

.successText {
  color: $color-green;
}
