body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* using important here to override the image cropper library styles  */
.reactEasyCrop_CropArea {
  color: white;
  width: 250px !important;
  max-height: 300px !important;
  max-width: 260px !important;
  height: 279.233px !important;
}

.flexBasis10 {
  flex-basis: 10%;
}

.flexBasis15 {
  flex-basis: 15%;
}

.flexBasis20 {
  flex-basis: 20%;
}

.flexBasis25 {
  flex-basis: 25%;
}

.flexBasis30 {
  flex-basis: 30%;
}

.flexBasis70 {
  flex-basis: 70%;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textMuted {
  color: grey;
}

.m-0 {
  margin: 0 0 0 0 !important;
}

.mt-05 {
  margin-top: 0.5em !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-05 {
  margin-right: 0.5em !important;
}

.ml-05 {
  margin-left: 0.5em !important;
}

.mb-05 {
  margin-bottom: 0.5em !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.reactEasyCrop_Container {
  overflow: inherit !important;
}

.reactEasyCrop_Contain {
  max-height: unset !important;
  min-width: 250px;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-height-70 {
  max-height: 70vh;
  overflow-y: auto;
}

.max-height-60 {
  max-height: 65vh;
  overflow-y: auto;
}

.w-90 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none !important;
}

textarea {
  font-family: inherit;
}

textarea::placeholder {
  font-style: normal !important;
}

.cursorPointer {
  cursor: pointer;
}

.flex-left {
  order: 24;
  margin-left: auto;
  height: 1em;
  margin-right: 1em;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.colorPrimary {
  color: #133970;
}

.bg-red {
  background-color: #a00b00 !important;
  border-color: #a00b00 !important;
}

.error {
  color: #a00b00 !important;
  border-color: #a00b00 !important;
}
