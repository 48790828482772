@import 'common/styles/colors.module.scss';

.candidateCardContainer {
  margin-bottom: 8px;
  align-items: center;

  & > div:first-child {
    box-shadow: none;
  }
}

.candidateAccordion {
  background-color: $alternative-bg-color;
}

.candidateAccordionDetails {
  padding: 3px 1px 0px 1px;
  display: block;
}

.candidatePhoto {
  height: 42px;
  width: 42px;
  border-radius: 10%;
  margin-left: -15px;
}

.candidateHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidateHeaderLeft {
  display: flex;
  justify-content: left;
  align-items: center;
}

.candidateName {
  font-weight: bold;
}

.candidateData {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 15px;
  color: $session-text-color;
  min-height: 50px;
}

.feedbackButton {
  color: $white;
  background-color: $entelect-green;
  width: 120px;
  height: 37px;
  font-size: small;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table td {
  border-left: 3px solid whitesmoke;
}

table td:first-child {
  border-left: none;
}

tr {
  border-bottom: 3px solid whitesmoke;
  width: 100%;
}

tr:last-child {
  border-bottom: none;
}

.summary {
  justify-content: end;
}

.candidateAccordionSummary {
  height: 40px;
  min-height: 48px !important;
  border: 1px solid $card-border-color;

  & > div:first-child {
    display: inline-grid;
    justify-content: space-between;
    grid-template-columns: 20% 10% 10% 20% 10% 20%;
    width: 100%;
  }
}
