@import 'common/styles/colors.module.scss';
.wrapper {
  display: flex;
  flex-direction: column;
}

.reportList {
  margin: 0px 16px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 0 0 15px !important;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $session-text-color;
}

.searchContainer {
  max-width: unset !important;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  margin: 0rem 0rem 2rem 0rem;
  flex-wrap: wrap;

  > div {
    margin-right: 24px !important;
  }

  > div:last-child {
    margin-right: 0 !important;
  }
}

.searchBar {
  flex: 2 1 0;
}

.filterButton {
  margin-top: 13px;
}

.tab {
  font-weight: 700;
  text-transform: none;
}

.paginationContainer {
  display: flex;
  justify-content: center;
}
