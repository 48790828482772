@import 'common/styles/colors.module.scss';

.filterCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: white;
  border-radius: 999;
  height: 10.5px;
  width: 10.5px;
  top: 0;
  right: 0;
  color: $entelect-blue;
  font-size: 7px;
  border: 1px solid $entelect-blue;
}

.filterContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.filterDrawerContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
  overflow: hidden;
}

.filterByContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding-top: 8px;
  margin-inline: 0;
}

.closeFilterDrawerButton {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.filterByHeader {
  font-size: 18px;
  font-weight: bold;
}

.filterMultiChip {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
}

.filterByHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.filterButtonContainer {
  color: white;
  background-color: $entelect-blue;
  min-width: 24px;
  padding: 6px;
  border-radius: 5px;
  display: block;
}

.filterOptionsContainer {
  width: 100%;
  display: contents;
}

.filterMultiSelect {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
}
