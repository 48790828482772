.title {
  font-weight: 800;
  font-size: 30px;
}

.authBox {
  height: 100%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.authContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.authDivider {
  width: 50%;
  border: 0.2px solid #d3d3d3;
  box-sizing: border-box;
  margin-top: 2em;
}

.authDividerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em 0.5em 1em;
  margin: -12px;
  background-color: #fff;
}
