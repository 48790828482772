.closeButton {
  position: absolute;
  top: 12%;
  right: 8%;
  padding: 5px;
  color: white;
  background-color: black;
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;
  z-index: 9000;
  font-size: 30px;
}

.sliderController {
  width: 100%;
  max-width: 250px;
  margin-top: 12px;
  padding: 0 5px;
  display: inline-flex;
  align-self: center;
}

.mainDiv {
  width: 100%;
  height: 385px;
  display: flex;
  flex-direction: column;
}

.cropContainer {
  color: white;
  width: 100%;
  height: 100%;
}
.superContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.reactEasyCrop_CropArea {
  color: white;
  width: 223.386px !important;
  max-height: 300px !important;
  max-width: 260px !important;
  height: 279.233px !important;
}
.subContainer {
  max-width: 250px;
  width: 100%;
  position: relative;
}
