.menuItem {
  border-bottom: 1px solid #c4c4c4;
  font-size: 16px !important;
  line-height: 30px;
  padding: 5px 10px;
}

.popover {
  border-radius: 5px;
}
