.modalBox {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: auto;
}

.modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  display: inline-block;
  text-align: left;
  padding: 0;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
}
