@import 'common/styles/colors.module.scss';
.wrapper {
  display: flex;
  flex-direction: column;
}

.archiveList {
  margin: 0px 16px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 0 0 15px !important;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $session-text-color;
}

.archiveButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.archiveButtons {
  margin-left: 16px;
}