@import 'common/styles/colors.module.scss';

.openDayCard {
  margin: 8px;
  // box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.15) !important;
  color: $black !important;
  font-weight: 500;
  width: 100%;
  padding: 0px !important;
  border: 1px solid $grey;
}

.cardMobile {
  margin: 0.5rem 0rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  color: $black !important;
  background-color: $white;
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  border: 1px solid $grey;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexTitleRow {
  flex: 2 1 0;
}

.feedbackLink {
  font-weight: 600;
  color: $entelect-blue;
  cursor: pointer;
  display: flex;
  justify-content: end;
}

.leftSection {
  flex: 1 1 35%;
  color: $white;
  padding: 15px 5px;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
}

.colorGreen {
  background-color: $success;
}

.colorBlue {
  background-color: $entelect-blue;
}

.rightSection {
  flex: 1 1 65%;
  padding: 0 16px;
  flex-direction: column;
  font-style: normal;
}

.cardContent {
  display: flex;
  justify-self: flex-start;
  align-items: center;
}

.chip {
  margin: 5px 0px;
  color: $black;
  font-weight: 600;
  background-color: $chip-bg-color !important;
  border: 1px solid $chip-border-color !important;
}

.attendees {
  margin-right: 5px;
}

.attendeesChips {
  margin-right: 5px;
  background: rgba(181, 181, 181, 0.2) !important;
  border: none !important;
}

.sessionHeading {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 16px;
}

.icons {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.completedIcon {
  color: $success;
}
