@import 'common/styles/colors.module.scss';

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  max-height: 95vh;
  border-radius: 5px;
  padding: 0;
  box-shadow: 24px;

  @media only screen and (min-width: 768px) {
    width: 90vw;
    height: 100vh;
  }
}

.boxWrap {
  border-bottom: 1px solid $grey;
  margin-bottom: 1rem;
  max-height: 55vh;
}

.header {
  background: $white;
  padding: 1rem 1rem 0;
  justify-content: space-between;
  border-radius: 5px;
  border-bottom: 2px solid $light-grey-bg-color;
}

.subTitle {
  color: $heavy-grey;
  font-weight: 600;
  font-size: 1.1em;
  max-height: fit-content;
}

.title {
  color: $heavy-grey;
  font-weight: 600;
  font-size: 1.5em;
  max-height: fit-content;
}

.iconClose {
  float: right;
  cursor: pointer;
}

.content {
  background: $white;
  padding: 0.5rem;
  height: 85%;
  width: 100%;
}

.contentCapture {
  @media only screen and (max-width: 768px) {
    min-height: 50vh;
  }
}

.footer {
  display: block;
  background: $white;
  padding: 0.5rem 0.5rem;
  border-radius: 0px 0px 5px 5px;
  border-top: 2px solid $light-grey-bg-color;
  text-align: right;
}

.buttonCancel {
  margin-right: 1%;
  color: $entelect-blue;
  border-color: $entelect-blue;
  font-weight: 600;
  width: 49%;
}

.buttonSubmit {
  color: $white;
  background-color: $entelect-blue;
  font-weight: 600;
  width: 49%;
  margin-right: 1%;

  &:disabled {
    background-color: $disabled !important;
  }
}

.feedbackCard {
  border-bottom: 1px solid $card-border-color;
  padding: 10px 0;
  height: 100%;

  &.feedbackCardTags {
    border-left: 1px solid $card-border-color;
    padding-left: 5px;
  }

  b {
    font-size: 0.9em;
  }

  span {
    font-size: 0.9em;
  }
}

.subHeading {
  text-align: center;
  width: 100%;
  margin-bottom: 0.1em;
  margin-top: 0;
}

.chip {
  margin: 5px 5px 0px 0px;
  color: $black;
  min-width: 10em;
  font-weight: 500;
  font-size: 0.8em;
  background-color: $chip-bg-grey !important;
}

.containerDiv {
  display: flex;
  flex-direction: column;
}

.feedbackNoteInput {
  border-color: $color-gray-3;
  width: 100%;
  resize: vertical;
  padding: 6px;
  margin-top: 7px;
  margin-bottom: 15px;

  &:focus,
  &:hover,
  &:active {
    border-color: $color-gray-2;
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    font-style: italic;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-grey;
  border-radius: 20px;
  border-right: 1px solid $white;
}

.feedbackHeader {
  font-weight: 600;
  font-size: 14px;
  color: #0000008a;
  margin-top: 0;
}

.feedbackNotes {
  margin-top: 0;
}

.feedbackCardUser {
  color: $black;
  font-weight: 600;
  font-size: 14px;
  margin-left: 0.5rem;
}

.feedbackCardText {
  color: $color-gray-2;
  font-size: 13px;
  padding-bottom: 10px;
}

.driveFile {
  height: 18px;
  width: 18px;
  margin: 2px 0px;
}

.accordion {
  border: solid 1px $color-gray-3;
  margin-bottom: 8px;
  box-shadow: none !important;
  margin-top: 0 !important;

  & > div:first-child {
    background-color: $color-gray-6;
    height: 40px;
    min-height: auto;
  }

  & > div:last-child {
    background-color: white;
  }
}

.accordionText {
  padding-top: 0.5rem !important;
  color: black;
  overflow-y: scroll;
  min-height: 50px;
  max-height: 200px;
  padding: 0.3rem;
  font-size: 0.9em;
  background-color: white;

  hr {
    background-color: $color-gray-6;
  }
}

.feedbackTextPreCalibration {
  background-color: #f0f0f0;
  padding: 0.5rem !important;
  border-bottom: 1px;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.feedbackNote {
  word-wrap: break-word;
  font-size: 12px;
  color: $paragraph-grey;
  margin-top: 0.3rem;
}

.feedbackUser {
  font-size: 12px;
  font-weight: bold;
}

.feedbackDate {
  font-size: 12px;
  font-style: italic;
}

.interviewNotesCardList {
  overflow-y: auto;
  max-height: 35vh;
}

.commentSection {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 38vh;
  padding-bottom: 0.5em;
  padding-right: 5px;
}

.offerSection {
  text-align: center;
  border-top: 1px solid rgb(139, 137, 137);
}

.preInterviewAuthor {
  text-align: right;
  font-style: italic;
  border-bottom: 2px solid rgb(139, 137, 137);
}

.maxContainerHeight {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  height: 80vh;
  padding-right: 5px;
}

.noteCaptureBox {
  display: block;
  padding-right: 5px;
  height: 73vh;
}

.feedbackType {
  color: $checkbox-green;
  margin: 25px 10px 0 10px;
  padding-bottom: 2px;
  border-bottom: 1px solid $card-border-color;
  width: 100%;
}
