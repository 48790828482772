.title {
  font-weight: 800;
  font-size: 30px;
}

.card {
  height: 100%;
  display: flex;
  width: 100%;
  border-radius: 1.7;
}

.cardHeader {
  display: flex;
  align-items: center;
  gap: 20px;

  .avatar {
    height: 100px;
    width: 100px;
  }

  > div > h4 {
    margin-block: 0px;
  }
}

.imageUploader {
  height: 170px;
  width: 108px;
  position: absolute;
  background-color: aquamarine;
  opacity: 0;
  cursor: pointer;
}

.uploadIcon {
  position: absolute;
  font-size: 2em;
  color: #ffffff;
  opacity: 0.4;
}

.cardBody {
  background: black;
  color: white;
  height: 50%;

  .candidateDetailContainer {
    display: flex;
    justify-content: space-between;
  }
}

.candidateName {
  font-size: 20px;
  line-height: 1.1;
  display: inline-block;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardPicture {
  height: 200px;
  width: 108px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-clip-margin: unset;
  overflow: visible;
  cursor: pointer;
}

.cardContent {
  padding: 10px 7px 5px 12px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.cardHeader,
.cardBody {
  padding: 12px;
}

/*Functional Styling*/
@media (pointer: coarse), (hover: none) {
  [title] {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }
}
