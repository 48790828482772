@import 'common/styles/colors.module.scss';

.dialogTitle {
  color: $entelect-blue;
  font-weight: 500;
  font-size: 18;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 8px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 21px !important;
}

.closeAction {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.dialogActions {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  background-color: $light-grey-bg-color;
}

.defaultNegativeButton {
  width: 100%;
  border: 1px solid $entelect-blue;
  color: $entelect-blue;
}

.defaultPositiveButton {
  width: 100%;
  background-color: $negative-button;
  color: $white;

  &:disabled {
    background-color: $disabled-button;
  }
}
