@import 'common/styles/colors.module.scss';

.uploadSection {
  height: fit-content;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.uploadActions {
  text-align: center;
  display: block;
  width: 100%;
}

.selectedFile {
  display: block;
  margin-left: 5px;
  margin-top: 13px;
  gap: 8px;
  width: 85%;
}

.closeIcon {
  color: $negative-button;
  cursor: pointer;
  display: inline-block;
  margin: -11px 7px;
}

.errorWarning {
  color: red;
  font-size: medium;
}

.viewInfoListItem {
  font-size: small;
}

.bulkImportContainer {
  display: flex;
}

.candidateList {
  margin: 0px 16px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $heavy-grey;
}

.candidateCard {
  color: $black !important;
  font-weight: 500;
  width: 100%;
  padding: 0px !important;
  border-radius: 6px;
  border: 1px solid var(--neutral-300, $grey);
  background: $light-grey-bg-color;
}

.cardContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px !important;
}

.statusValid {
  border-left: 8px solid $entelect-green;
}

.statusInvalid {
  border-left: 8px solid $error;
}

.statusDuplicate {
  border-left: 8px solid $warning;
}

.statusValidColor {
  color: $entelect-green;
}

.statusInValidColor {
  color: $error;
}

.statusDuplicateColor {
  color: $warning;
}

.viewInfoFontSize {
  font-size: medium;
}

.indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.indicatorValid {
  background-color: $entelect-green;
}

.indicatorInvalid {
  background-color: $error;
}

.indicatorDuplicate {
  background-color: $warning;
}

.bulkImportDropDown {
  min-width: 400px;
}

.greyButton {
  background: var(--neutral-200, $light-grey);

  color: $black;
  margin-right: 8px;

  :active {
    background: var(--neutral-200, medium-grey);
  }
}

.verificationActions {
  display: flex;
  justify-content: end;
}

.filterAndInfoSection {
  display: flex;
  margin-top: 16px;
}

.infoSection {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.bulkImportNegativeButton {
  width: 100%;
  background-color: $negative-button;
  color: $white;
}

.bulkImportPositiveButton {
  width: 100%;
  background-color: $entelect-green;
  color: $white;
}

.bulkUploadLoader {
  margin-inline: 5px;
  width: 100%;
}

.cancelUploadAction {
  display: flex;
}

.backButton {
  color: $entelect-blue;
  width: 40px;
  height: 40px;
  cursor: pointer;
  float: left;

  .backArrow {
    color: $back-arrow;
    min-height: 25px;
    min-width: 25px;
  }
}

.openListItems {
  border: 1px solid black;
}

.listbox {
  border: 1px gray solid;
  border-radius: 2px;
}

// new dialog styles
.dialogContent {
  height: 164px;
  width: 474px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0px;
}

.dialogActionArea {
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}

.dialogActionButton {
  width: 46px;
  height: 36px;
}

.hidesection {
  display: none;
}

.uploaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1475cf;
  height: 185px;
  width: 500px;
  cursor: pointer;
  border-radius: 10px;
}

.pagecontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.onHoverDragAndDrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1475cf;
  height: 185px;
  width: 500px;
  border-radius: 5px;
  background-color: aliceblue;
}

.uploadIcon {
  height: 76px;
}

.fileError {
  color: red;
}

.uploadedFileContainer {
  border: solid;
  border-color: #133970;
  width: 500px;
  height: 80px;
  border-radius: 10px;
  border-width: 2px;
  display: flex;
}

.progressBar {
  width: 90%;
  height: 9px;
  border-radius: 10px;
  background-color: #e6e6e6;
  margin-bottom: 10px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.progressBarFill {
  height: 100%;
  border-radius: 10px;
  background-color: #2ecc71;
  transition: width 0.5s ease-out;
}

.progressLabel {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #444444;
}

.container {
  width: 200px;
  margin-top: 200px;
}

.buttonUploadContainer {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.progressIndicator {
  margin-left: 100%;
  padding-left: 3px;
}

.fileIcon {
  height: 81px;
  font-size: xxx-large;
  color: $entelect-blue;
}

.progress {
  width: 0%;
  height: 9px;
  background-color: $entelect-blue;
  border-radius: 8px;
  transition: width 0.5s;
  display: flex;
  align-items: center;
}

.uploadActionOverlay {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.uploaderContainerOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px dashed #4d4d4d;
  height: 185px;
  width: 500px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}
