.title {
  font-weight: 500;
  font-size: 28px;
  color: #7b7b7b;
}

.header {
  padding: 16px 16px 24px 16px;
  width: 100%;
  display: flex;
  gap: 24px;
}

.leftHeader {
  flex-basis: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rightHeader {
  flex-basis: 20%;
}

.filterBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
