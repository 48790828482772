@import 'common/styles/colors.module.scss';

.justifyContentCenter {
  justify-content: center;
}

.maxHeight80 {
  max-height: 80vh;
  overflow: scroll;
}

.newUserButton {
  width: 100%;
}
