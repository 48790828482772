@import 'common/styles/colors.module.scss';

.userCard {
  margin: 8px;
  color: #000000 !important;
  font-weight: 500;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid var(--neutral-300, #c4c4c4);
  background: #fff;
}

.cardMobile {
  margin: 0.5rem 0rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  color: #000000 !important;
  background-color: #fff;
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  border: 1px solid #c4c4c4;
}

.flexRow {
  display: flex;
  flex-direction: row;
  padding: 4px 0;
}

.flexTitleRow {
  flex: 2 1 0;
  font-weight: bold;
}

.userLink {
  font-weight: 600;
  color: $entelect-blue;
  cursor: pointer;
  text-align: right;
}

.chip {
  margin: 5px 5px 5px 0;
  color: #000000;
  font-weight: 600;
  border-radius: 100px;
  background: rgba(181, 181, 181, 0.2);
}

.icons {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.mobileChips {
  display: flex;
  flex-direction: column;
}

.statusRow {
  display: flex;
}

.email {
  flex-basis: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px !important;
}

.statusActive {
  color: green;
}

.statusInActive {
  color: lightgray;
}

.status {
  padding-left: 8px;
}

.inlineFlexCenter {
  display: inline-flex;
  align-items: center;
}
