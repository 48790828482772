@import 'common/styles/colors.module.scss';

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $entelect-green;
  height: 36px;
  border-radius: 5px;
  font-size: 14;
  font-weight: 500;
  min-width: 178px;
}
