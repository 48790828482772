@import 'common/styles/colors.module.scss';

.image {
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #0000004d;
  width: 500px;
  height: 600px;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  max-height: 95vh;
  border-radius: 4px;
  padding: 0;
  box-shadow: 24px;
  background-color: $black;
  width: 500px;
  height: 600px;
}
