@import '../../../../common/styles/colors.module.scss';

.modalBox {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: auto;
}

.modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 30%;
  display: inline-block;
  text-align: left;
  padding: 0 0.5em;

  @media only screen and (max-width: 768px) {
    width: 90%;
    top: 45%;
  }
}

.iconClose {
  align-self: center;
  cursor: pointer;
  float: right;
}

.avatar {
  height: 64px;
  width: 62px;
}

.listItems {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.54);
  border-radius: 5px;
}

.list {
  list-style: none;
  max-height: 50vh;
  overflow-y: auto;
  padding: 0 10px 0 0;
  margin: 10px 0 0 0;
}

.totalSelected {
  padding: 10px;
}

.noCandidates {
  padding: 10px;
  text-align: center;
  font-size: large;
}
