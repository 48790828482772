@import 'common/styles/colors.module.scss';

.primary {
  display: 'flex';
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $entelect-blue;
  border: 1px solid $black;
  height: 39px;
  border-radius: 4px;
  gap: 20px;
}

.secondary {
  display: 'flex';
  justify-content: center;
  align-items: center;
  color: $entelect-blue;
  background-color: $white-smoke;
  border: 2px solid $entelect-blue;
  height: 39px;
  border-radius: 4px;
  gap: 20px;
}

.success {
  display: 'flex';
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $entelect-green !important;
  height: 37px;
}

.secondary:active {
  background: $white-smoke !important;
}

.link {
  color: $entelect-blue !important;
}

.disabledprimary {
  background-color: $disabled-button !important;
  color: $white !important;
  border-color: $disabled-button !important;
}

.linkButton {
  display: 'flex';
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $entelect-green !important;
  height: 33px;
}

.danger {
  display: 'flex';
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $negative-button !important;
  border-color: $negative-button !important;
}

.dangerLink {
  justify-content: center;
  align-items: center;
  color: $negative-button;
  background-color: $white !important;
  border-color: $negative-button !important;
}
