@import 'common/styles/colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.feedbackList {
  margin: 30px 0px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $session-text-color;
}

.searchContainer {
  max-width: unset !important;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  margin: 0rem 0rem 0.5rem 0rem;
  flex-wrap: wrap;

  > div {
    margin-right: 24px !important;
  }

  > div:last-child {
    margin-right: 0 !important;
  }
}

.searchBar {
  flex: 2 1 0;
}

.filterButton {
  margin-top: 13px;
}

.tab {
  font-weight: 700;
  text-transform: none;
}

.paginationContainer {
  display: flex;
  justify-content: center;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $entelect-green;
  height: 36px;
  border-radius: 5px;
  font-size: 14;
  font-weight: 500;
  min-width: 178px;
}
