.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 0;
  border-radius: 5px;
}

.header {
  background: #f6f6f6;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #4a5568;
  font-weight: 600;
}

.iconClose {
  align-self: center;
  cursor: pointer;
}

.content {
  background: #fff;
  padding: 1rem;
}

.footer {
  background: #f6f6f6;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 5px;
}

.buttonCancel {
  margin-right: 1rem !important;
  color: #133970 !important;
  border-color: #133970 !important;
  font-weight: 600;
}

.buttonArchive {
  color: #fff;
  background-color: #c23f38 !important;
  font-weight: 600;
}
