@import 'common/styles/colors.module.scss';

.customSelect {
  & > div {
    padding: 0.75em;
  }
}

.backButton {
  color: $entelect-blue;
  width: 40px;
  height: 40px;
  cursor: pointer;
  float: left;

  .backArrow {
    color: $back-arrow;
    min-height: 25px;
    min-width: 25px;
  }
}
