@import 'common/styles/colors.module.scss';

.reportCard {
  margin: 0.5rem 0rem;
  box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.15) !important;
  color: $black !important;
  font-weight: 500;
  width: 100%;
  padding: 0px !important;
  border: 0.5px solid #c4c4c4;
}

.cardMobile {
  margin: 0.5rem 0rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  color: $black !important;
  background-color: $white;
  font-weight: 500;
  width: 100%;
  padding: 0px !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.statusFuture {
  border-left: 8px solid $color-orange;
}

.statusComplete {
  border-left: 8px solid $color-green2;
}

.statusToday {
  border-left: 8px solid $color-green;
}

.actionLinksWrapper {
  flex-basis: 10%;
  font-weight: 600;
  color: $entelect-green;
  cursor: pointer;
  text-align: right;
}

.actionLink {
  display: inline-flex;
  margin-left: 1em;
  border: solid white 1px;
  padding: 18px 7px;
  border-radius: 6px;
}

.leftSection {
  flex: 1 1 35%;
  color: $white;
  padding: 15px 5px;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
}

.colorGreen {
  background-color: $entelect-green;
}

.colorBlue {
  background-color: $entelect-green;
}

.rightSection {
  flex: 1 1 65%;
  padding: 0 16px;
  flex-direction: column;
  font-style: normal;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 16px 0px 24px;
  padding: 7px 0 7px 7px !important;
}

.chip {
  margin: 5px 0px;
  color: $black;
  font-weight: 600;
  background-color: $chip-bg-color !important;
  border: 1px solid $chip-border-color !important;
}

.reportTime {
  font-size: 12px;
}
