@import 'common/styles/colors.module.scss';

.candidateFormButtons {
  button,
  a {
    width: 100%;
  }
}

.jfInput {
  min-width: 100% !important;
}

.jfUploadArea {
  margin-top: 4px;
  height: 120px;
  padding: 5px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  box-sizing: border-box;
  width: 250px;
  height: 260px;

  // background: #FFFFFF;
  /* Neutral/500 */

  border: 2px dashed #7b7b7b;

  .desktop-upload-button {
    display: none;
  }
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  overflow: hidden;
}

.desktopUploadButton {
  border-color: $entelect-blue;
  color: $entelect-blue;
  margin: 54px auto;
  max-width: 250px;
  height: 70px;
}

.verticalDivider {
  display: inline-flex;

  @media screen and (max-width: 900px) {
    .verticalDivider {
      display: none;
    }
  }
}

.cropperContainer {
  height: 360px;
  border-radius: 5%;
  position: relative;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
}

.dropzoneContainer {
  border: 2px #133970 dotted;
  margin-top: 30px;
  max-width: 250px;
  width: 100%;
}

.jfRelocationContainer {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.recruiterSearchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.openDayContainer {
  display: inline !important;
}

#candidateNotAttendingNotification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;

  width: 358px;
  height: 72px;
  left: 16px;
  top: 1037px;

  background: $color-notification-blue;
  border-radius: 6px;
}

.imageSaveActions {
  display: flex;
  justify-content: right;
  padding: 1px;
}

.imageCancelActions {
  display: flex;
  justify-content: left;
  padding: 1px;
}

.verticalDivider {
  margin: 0 42px;
  padding: 2px;
  background: rgba(0, 0, 0, 0.12);
}

.w100 {
  width: 100%;
}

.backArrow {
  color: $back-arrow;
  min-height: 25px;
  min-width: 25px;
}

.placeHolderForNoUploadButton {
  margin-top: 30px;
}

.gridAlignment {
  align-items: center;
}
