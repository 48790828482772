.searchContainer {
  width: 100% !important;
  display: flex;
  flex: 1;
  gap: 24px;
  align-items: center;
  margin-inline: 0;
}

.filterButton {
  color: white;
  background-color: #133970;
  height: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
