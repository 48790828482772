@import '../../../common/styles/colors.module.scss';

.modalBox {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: auto;
}

.modal {
  width: 50%;
  display: inline-block;
  text-align: left;
  padding: 0 2em;
  max-height: 85vh;
  margin-top: 10vh;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    width: 90%;
    padding: 0;
  }
}

.addButton {
  float: right;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0.25em !important;
    float: none;
  }
}

.colorSelection {
  display: inline-flex;
  margin: 0;
  padding: 0;

  li {
    display: inline-flex;

    span {
      width: 1.5em;
      height: 1.5em;
      display: block;
      cursor: pointer;
      margin-right: 0.3em;
    }

    div {
      margin-left: 0.4em;
    }
  }
}

.chip {
  border: solid;
  padding: 0.2em 1em;
  border-radius: 1em;
  display: inline;
}

.row {
  padding: 0.2em;
  cursor: pointer;

  &:hover {
    background-color: $card-border-color;
  }

  & > div {
    padding: 0.3em;
    border: solid #dfdfdf 1px;
    border-radius: 5px;

    & > div {
      align-content: center;
    }
  }

  .actionButtons {
    text-align: right;

    button {
      padding: 0.2em 0.15em;
    }
  }
}

.title {
  & > b {
    font-size: 23px;
    text-transform: uppercase;
  }

  margin-bottom: 1em;
  margin-top: 0;
}
