@import '../../../../common/styles/colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.headerTop {
  background-color: $color-primary;
  color: $color-white;
  margin: -1px 0;
}

.headerGoBackLink {
  padding: 0.3rem 0.5rem;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.headerTeamCaptain {
  padding: 0.3rem 0.5rem;
  font-weight: 500;
  font-size: 1.5em;
  justify-content: center;
  display: flex;
}

.header {
  width: -webkit-fill-available;
  border-radius: 5px 5px 0px 0px;
  background-color: #eeeeee;
  display: flex;
  height: 5rem;
}

.headerMobile {
  width: -webkit-fill-available;
}

.headerDetails {
  width: -webkit-fill-available;
  background-color: $color-primary;
  color: #fff;
  display: flex;
  height: 5rem;
  justify-content: space-between;

  & > div {
    padding: 0.5rem 1rem;
    cursor: pointer;

    & > span > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.teamHeaderDetails {
  width: -webkit-fill-available;
  background-color: #85b94b;
  color: #fff;
  height: 5rem;
  cursor: pointer;
  justify-content: space-between;

  & > div {
    padding: 0.5rem 1rem;
  }
}

.fontSize24 {
  font-size: 24px;
}

.fontSize15 {
  font-size: 15px;
}

.meta {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0.5rem 1rem;
}

.location {
  margin: 0;
  border: 1px solid #009499;
  background-color: #dff7f7;
  width: fit-content;
  margin: 5px 0;
}

.candidateTrackerLink {
  display: inline-flex;
  margin-top: 5px;
  border: solid white 1px;
  padding: 16px 7px !important;
  border-radius: 6px;
  text-transform: capitalize;
  color: white;
  font-weight: 400;
}

.avatar {
  border-radius: 5px 5px 5px 5px;
  height: 5rem;
  width: 75px !important;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidateName {
  padding-top: 2px;
  display: block;
}

.avatarMobile {
  border-radius: 5px;
  height: 60px !important;
  width: 60px !important;
  margin-top: 5px;
  background-color: lightgray;
  align-items: center;
  justify-content: center;
}

.noteCapture {
  border: none;
  border-radius: 5px;
  margin: 1rem 0rem 0;
  border-bottom: none;
}

.noteCaptureWrapper {
  padding-bottom: 0.5rem;
}

.feedbackAdditional {
  border: 1px solid $color-gray-3;
  border-radius: 5px;
  margin: 1rem 1rem 0rem;
}

.offersAdditional {
  border-top: 1px solid $color-gray-3;
}

.noteCaptureHeader {
  padding-top: 2px;
  padding-bottom: 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #e1e1e1;
  border-bottom: 1px solid $color-gray-3;
}

.tagHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
}

.feedbackNoteInput {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0;
  border: 1px solid #c4c4c4;
  border-color: $color-gray-3;
  width: -webkit-fill-available;
  resize: vertical;
  font-size: 16px;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    font-style: italic;
  }
}

.buttonGroup {
  width: max-content;
  align-self: flex-end;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: auto;
}

.feedbackTags {
  border-radius: 5px;
  margin: 0.4rem 0.4rem;
}

.feedbackCategorySelection {
  height: 25px;
  width: 175px;
  margin: 2px 5px;
  border-color: $color-primary !important;
  background-color: #fff;
  font-size: 13px !important;
  font-weight: 700 !important;
}

.feedbackTagsHeader {
  line-height: 3rem;
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
  cursor: pointer;
}

.buttonContained {
  background-color: $color-primary !important;
  color: #fff !important;

  &:disabled {
    cursor: not-allowed;
    background-color: lightgray !important;
  }
}

.buttonOutlined {
  background-color: #fff !important;
  color: $color-primary !important;
  border-color: $color-primary !important;

  &:disabled {
    cursor: not-allowed !important;
    color: $color-gray-3 !important;
    border-color: $color-gray-3 !important;
  }
}

.chip {
  margin: 5px 5px 0px 0px;
  color: #000000;
  width: fit-content;
  font-weight: 600;
  background-color: #e1e1e1 !important;
  border: 1px solid #009499 !important;
}

.feedbackNoteContainer {
  position: relative;
  border: 1px solid $color-gray-3;
  background-color: #fafafa;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.teamFeedbackNoteContainer {
  position: relative;
  border: 1px solid $color-gray-3;
  background-color: $color-team-green;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.sendOffer {
  border: 2px solid #00a9b2;
}

.userAvatar {
  width: 65px !important;
  height: 60px !important;
  border: 1px solid $color-primary;
  border-radius: 5px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #e1e1e1;
  margin-right: 0.5rem !important;
}

.feedbackNoteHeader {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  width: 100%;
}

.feedbackNoteMessage {
  margin: 5px 0px;
  color: $color-gray-2;
  overflow-wrap: break-word;
  font-size: 14px;
  white-space: pre-wrap;
}

.feedbackNoteTagsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feedbackNoteTagsMobile {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.editMenu {
  float: right;
  bottom: 0;
}

.feedbackNoteTags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feedbackTagChips {
  line-height: 30px;
  margin-bottom: 10px;
}

.radioGroup {
  margin-left: 1rem;
  font-size: 15px;
  color: $color-gray-2;
}

.verifiedIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  padding: 0;
  color: #00a9b2;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbbbb;
  border-radius: 20px;
  border-right: 1px solid white;
}

.editContainer {
  margin: 0 0.5rem;
}

.editElementBorder {
  border: 1px solid $color-gray-3 !important;
  border-radius: 5px !important;
  width: 100% !important;
  width: -webkit-fill-available;
}

.editFeedbackTextBox {
  resize: none;
  font-size: 16px;
  padding: 0.5rem;
  border: none !important;
  width: 100%;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.feebackTagsFlex {
  padding-left: 8px;
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.editButtonGroup {
  display: flex;
  flex-direction: row;
}

.divider {
  color: '#4C5667';
  font-size: 13px;
}

.selectedCandidate {
  background-color: $color-primary;

  & > .avatarMobile {
    height: 75px !important;
    width: 75px !important;
  }

  & > .avatar {
    width: 90px !important;
    height: 90px;
  }
}

.selectedTeam {
  background-color: #85b94b;

  & > .avatarMobile {
    height: 75px !important;
    width: 75px !important;
  }

  & > .avatar {
    width: 90px !important;
    height: 90px;
  }
}

.candidateList {
  display: flex;
  overflow-x: scroll;
  margin: -1px 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    padding: 0.35em 0.4em 0.2em 0.4em;
    line-height: 1;
    cursor: pointer;
    border-radius: 5px 5px 0 0;

    & > span {
      display: block;
      text-align: center;
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 75px;
    }
  }
}

.feedbackContainerHeader {
  font-weight: 600;
  font-size: 15px;
  color: #8abb4f;
}

.feedbackContainerHeaderGreen {
  font-weight: 600;
  font-size: 15px;
  color: $color-green2;
}

.noteSubHeader {
  width: 100%;

  & > .time {
    float: right;
    font-size: 13px;
    font-weight: 600;
    color: $color-primary;
    margin-right: 5px;
  }
}

.feedbackNoteUser {
  width: 100%;
}

.teamAvatar {
  border: 2px solid #1596a1;
  color: #1596a1;
  font-size: 43px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 !important;
}
