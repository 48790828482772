@import '../../../../common/styles/colors.module.scss';

.chip {
  margin-right: 4px !important;
  background-color: $color-gray-5 !important;
  border: 1px solid $color-primary-light !important;
  margin-bottom: 5px;

  b {
    padding: 0 2px 0 0em;
    color: $entelect-blue;
  }
}

.menuItem {
  border-bottom: 1px solid #c4c4c4;
  font-size: 15px !important;
  line-height: 2;
  padding: 0px 10px;
}

.popover {
  border-radius: 5px;
}

.title {
  margin: 0;
  padding: 0.5em;
  color: $entelect-blue;
  border-bottom: solid 1px $color-gray-3;
}

.displayChip {
  margin: 5px 5px 0px 0px;
  color: $black;
  min-width: 10em;
  font-weight: 500;
  font-size: 0.8em;
  background-color: $chip-bg-grey !important;
}
