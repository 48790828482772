@import 'common/styles/colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.feedbackList {
  margin: 0px 16px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $session-text-color;
}

.searchContainer {
  max-width: unset !important;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  margin: 0rem 0rem 1.5rem 0rem;
  flex-wrap: wrap;
}

.filterButton {
  margin-top: 13px;
}

.searchContainer > * {
  margin-right: 24px !important;
}

.mobileAddButton {
  flex-basis: 100%;
}

.searchBar {
  flex: 2 1 0;
}

.addButton {
  margin-top: 8px;
  align-items: center;
  color: $white;
  background-color: $entelect-green;
  height: 36px;
  border-radius: 5px;
  font-size: 14;
  font-weight: 500;
  min-width: 178px;
}

.sortableCol {
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.tab {
  font-weight: 700;
}

.searchInput {
  background-color: $white;
}

.paddingBottom1rem {
  padding-bottom: 1rem;
}

.paginationContainer {
  display: flex;
  justify-content: center;
}
