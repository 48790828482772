@import 'common/styles/colors.module.scss';

.card {
  max-height: 192px;
  display: flex;
  width: 100%;

  &:hover {
    border-color: $entelect-blue;
  }
}

.cardContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.heading {
  font-weight: 700;
  font-size: 1.25em;
  justify-self: left;
}

.headingColorGreen {
  color: $entelect-green;
  font-weight: 700;
  font-size: 1.25em;
  justify-self: center;
}

.headingColorBlue {
  color: $entelect-blue;
  font-weight: 700;
  font-size: 1.25em;
  justify-self: center;
}

.headingCenter {
  display: grid;
  grid-auto-flow: column;
}

.location {
  font-weight: 600;
  font-size: 1em;
  justify-self: center;
}

.candidateInfo {
  display: flex;
  flex-flow: row;
  font-weight: 400;
  font-size: 0.9em;
}

.statusFuture {
  border-left: 8px solid $color-orange;
}

.statusComplete {
  border-left: 8px solid $color-green2;
}

.statusToday {
  border-left: 8px solid $color-green;
}
