@import 'common/styles/colors.module.scss';

.customSelect {
  & > div {
    padding: 0.75em;
  }
}

.backButton {
  .backArrow {
    color: $back-arrow;
  }
}

.imgStyle {
  width: 33px;
  position: absolute;
  top: 3px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
  height: 33px;
  cursor: pointer;
}
